import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './Dashboard.css';

function Messages() {
 
    return (
        <div>
            {/* <iframe width="800" height="600" src="https://app.powerbi.com/reportEmbed?reportId=6915d911-19ca-4866-b404-3588f93027a4&groupId=6b59b446-946f-4cd9-8db4-605949a2166f" frameborder="0" allowFullScreen="true"></iframe> */}
            {/* <iframe title="Gibson_UI_v1.3.2" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=dee65843-3876-4415-a3b4-f1da99151366&autoAuth=true&ctid=b917edf1-b482-4145-a6a5-c028777c3168" frameborder="0" allowFullScreen="true"></iframe> */}
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create

                    id: 'dee65843-3876-4415-a3b4-f1da99151366',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=dee65843-3876-4415-a3b4-f1da99151366&groupId=6b59b446-946f-4cd9-8db4-605949a2166f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRy1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d',
                    accessToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSIsImtpZCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYjkxN2VkZjEtYjQ4Mi00MTQ1LWE2YTUtYzAyODc3N2MzMTY4LyIsImlhdCI6MTcxMDQwMjY1MywibmJmIjoxNzEwNDAyNjUzLCJleHAiOjE3MTA0MDcyNDUsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84V0FBQUFRL1ZVUVFEWXNhMGxaa283cStGUVExNE9NdUszb0l4YWpQMXBlTUxRZ2NoM0tXKzNiZ28rVWkrVFZoZTJJeWYyIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMCIsImZhbWlseV9uYW1lIjoiQXNoaSIsImdpdmVuX25hbWUiOiJBYmhpa2h5YSIsImlwYWRkciI6IjEwMy4xNzMuOTMuNTEiLCJuYW1lIjoiQWJoaWtoeWEgIEFzaGkiLCJvaWQiOiIwYzFmZWEzOS05YzA5LTQ0MzMtODU5My05ZTU3N2FlNGJlMmYiLCJwdWlkIjoiMTAwMzIwMDM1QTlDQjcxNCIsInJoIjoiMC5BU2dBOGUwWHVZSzBSVUdtcGNBb2Qzd3hhQWtBQUFBQUFBQUF3QUFBQUFBQUFBQW9BS0EuIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic2lnbmluX3N0YXRlIjpbImttc2kiXSwic3ViIjoiSF9GQ05ocVU3UVlwM3NvTVVGUTgxM3EwSnJCR3psVmFSamZ0RGZjUGk5dyIsInRpZCI6ImI5MTdlZGYxLWI0ODItNDE0NS1hNmE1LWMwMjg3NzdjMzE2OCIsInVuaXF1ZV9uYW1lIjoiQWJoaWtoeWFAY2xvdWRjb3VydGluYy5jb20iLCJ1cG4iOiJBYmhpa2h5YUBjbG91ZGNvdXJ0aW5jLmNvbSIsInV0aSI6Ii1DR25jMzF1Y0U2MDVxWmZ4NWxXQUEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdfQ.GFrALCh9oLU1ecsc6NVyHigAMHVP-3up5RXBnkrURh5PJAuyLzyoSXg6xdu2KBdpjSWc6_jQ3lM7xNglCVFNc3BkTNIUNe1udZpOPmeQMT7s_ssl6KKCgwisJqeo8OHKq69zUfSB8Q01V1HHgRFRQmmwtxbdBN4ytdeZvhP0zxQZHVBeICTHMkGCNpioUagSS3HQxtIEEFBScizFWJ_rM1gblYTFo9xPb4LB7MVIyoxthLQ2NCccEw8cRODT8_-mYg-6uMltsXQ42X1pxpLWDlyadc4laMHKdH2MwcdR90vRndr0GJ37MxaKiuFnRDiBy8MLihZzzHqZx2f8HXjtfw',
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName = {
                    "custom-powerbi-embed"
                }
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            />
        </div>
    );
}

export default Messages;