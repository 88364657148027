import React from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import './Dashboard.css';

function Dashboard() {
 
    return (
        <div>
            {/* <iframe width="800" height="600" src="https://app.powerbi.com/reportEmbed?reportId=6915d911-19ca-4866-b404-3588f93027a4&groupId=6b59b446-946f-4cd9-8db4-605949a2166f" frameborder="0" allowFullScreen="true"></iframe> */}
            <iframe title="Gibson_UI_v1.3.2" width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=dee65843-3876-4415-a3b4-f1da99151366&autoAuth=true&ctid=b917edf1-b482-4145-a6a5-c028777c3168" frameborder="0" allowFullScreen="true"></iframe>
            {/* <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create

                    // id: '6915d911-19ca-4866-b404-3588f93027a4',
                    // embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=6915d911-19ca-4866-b404-3588f93027a4&groupId=6b59b446-946f-4cd9-8db4-605949a2166f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRy1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d',
                    id: 'dee65843-3876-4415-a3b4-f1da99151366',
                    embedUrl: 'https://app.powerbi.com/reportEmbed?reportId=dee65843-3876-4415-a3b4-f1da99151366&groupId=6b59b446-946f-4cd9-8db4-605949a2166f&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRy1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d?rs:embed=true',
                    accessToken: '..----wo--',
                    tokenType: models.TokenType.Aad, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                    }
                }}

                eventHandlers={
                    new Map([
                        ['loaded', function () { console.log('Report loaded'); }],
                        ['rendered', function () { console.log('Report rendered'); }],
                        ['error', function (event) { console.log(event.detail); }],
                        ['visualClicked', () => console.log('visual clicked')],
                        ['pageChanged', (event) => console.log(event)],
                    ])
                }

                cssClassName = {
                    "custom-powerbi-embed"
                }
                getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                }}
            /> */}
        </div>
    );
}

export default Dashboard;