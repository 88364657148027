import React, { useState, useReducer, useEffect } from "react";
import htmlDocx from "html-docx-js/dist/html-docx";
import SideBar from "../components/Sidebar/SideBar";
import { PageLayout } from "../components/PageLayout";
import GibsonLogo from "../img/gibson_logo.png";
import WitnessMetaData from "../data/transcripts/File_metadata_master.json";
import ExtractionJobs from "../data/transcripts/ExtractionJobs.json";
import Transcript1 from "../data/summary/IPR2019-01313 1022 - Lipson Deposition.json";
import Transcript2 from "../data/summary/IPR2017-01812 1126 - Joey Chen Deposition.json";
import Transcript3 from "../data/summary/IPR2019-01313 1023-Meadows Deposition.json";
import Transcript4 from "../data/summary/IPR2019-01318 2012 - Pless Deposition.json";
import Transcript5 from "../data/summary/IPR2019-01318 2013 - Kroll Transcript.json";

const initialState = {
  selectedOption: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SELECT_OPTION":
      return { ...state, selectedOption: action.payload };
    default:
      return state;
  }
}
const NewPage = () => {
  const [witnessName, setWitnessName] = useState("");
  const [witnesses, setWitnesses] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    "IPR2019-01313 1022 - Lipson Deposition"
  ); // Initialize to the value of the first radio button
  const [sortedData, setSortedData] = useState(Transcript1);

  useEffect(() => {
    const witnesses = WitnessMetaData.filter(
      (witnessObj) => witnessName === witnessObj.witness_name
    );
    if (witnesses.length > 0) {
      setWitnesses(witnesses);
    } else {
      setWitnesses("");
    }

   

    // setSortedData(sorted);
  }, [witnessName]);

  const sortData = (transcript) => {
    const sorted = {};
    // Iterate over each key in the JSON data object
    Object.keys(transcript).forEach((key) => {
    // Sort each array by the 'date' property
    const sortedArray = transcript[key].sort(
      (a, b) => new Date(a.startTime) - new Date(b.startTime)
    );
    sorted[key] = sortedArray;
    setSortedData(sorted);
  });
  }
  
  useEffect(() => {
    // Parse dates and sort data on component mount
  }, []);
  const downloadAsWord = () => {
    const divContent = document.getElementById("content").innerHTML;

    // Convert HTML content to Word document format
    const wordDocument = htmlDocx.asBlob(divContent);

    // Create a link element
    const a = document.createElement("a");
    // Create a URL to the blob
    const url = URL.createObjectURL(wordDocument);
    // Set the href attribute of the link to the URL of the blob
    a.href = url;
    // Set the filename for the downloaded file
    a.download = `${selectedOption}_Summary.docx`;
    // Append the link to the body
    document.body.appendChild(a);
    // Trigger the click event on the link
    a.click();
    // Remove the link from the body
    document.body.removeChild(a);
    // Revoke the URL to release the blob's resources
    URL.revokeObjectURL(url);
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOptionChange = (option, index) => {
    setSelectedOption(option);
    dispatch({ type: "SELECT_OPTION", payload: option });
    if (option == "IPR2019-01313 1022 - Lipson Deposition") {
      // setTranscript(Summary1);
      // setSortedData(Transcript1)
      sortData(Transcript1);
    } else if (option == "IPR2017-01812 1126 - Joey Chen Deposition") {
      // setTranscript(Transcript2);
      sortData(Transcript2)
      // setSortedData(Transcript2)

    } else if (option == "IPR2019-01313 1023-Meadows Deposition") {
      // setTranscript(Transcript3);
      sortData(Transcript3);
      // setSortedData(Transcript3)

    } else if (option == "IPR2019-01318 2012 - Pless Deposition") {
      // setTranscript(Transcript4);
      setSortedData(Transcript4)

      // sortData(Transcript4);
    }
    if (option == "IPR2019-01318 2013 - Kroll Transcript") {
      // setTranscript(Transcript5);
      setSortedData(Transcript5)

      // sortData(Transcript5);

    }
    console.log("My option", option);
  };

  const handleSearchByWitness = (e) => {
    setWitnessName(e.target.value);
  };

  return (
    <>
      <div>
        <br />
        <PageLayout>
          <SideBar>
            <img
              src={GibsonLogo}
              alt="logo"
              width="380"
              class="rounded mx-auto d-block"
            />

            <div className="row">
              <div className="col-md-4">
                <div className="container">
                  <p className="fw-bold">Search by Witness Name</p>
                  <input
                    type="text"
                    class="form-control mb-4"
                    id="exampleFormControlInput1"
                    placeholder="Search"
                    onChange={(e) => handleSearchByWitness(e)}
                  />
                  <p className="fw-bold">Select transcript to summarize</p>
                  {witnesses
                    ? witnesses
                        .filter((item) => {
                          return witnesses.witness_name.toUpperCase() === ""
                            ? item
                            : item.witness_name
                                .toUpperCase()
                                .includes(witnessName.toUpperCase());
                        })
                        .map((option, index) => (
                          <div key={index}>
                            <input
                              type="radio"
                              id={`option${index}`}
                              name="options"
                              value={option.transcript_name} // Assuming 'value' is the property of the object representing the option
                              checked={selectedOption == option.transcript_name}
                              onChange={() =>
                                handleOptionChange(
                                  option.transcript_name,
                                  index
                                )
                              }
                            />
                            <label htmlFor={`option${index}`} className="ms-3">
                              {option.transcript_name}
                            </label>{" "}
                            {/* Assuming 'label' is the property of the object representing the option */}
                          </div>
                        ))
                    : WitnessMetaData &&
                      WitnessMetaData
                      .filter((item) => {
                        return (WitnessMetaData.witness_name && WitnessMetaData.witness_name.toLowerCase())
                          ? // .toLowerCase() === ""
                            item
                          : item.witness_name
                              .toLowerCase()
                              .includes(witnessName.toLowerCase());
                      }).map((option, index) => (
                        <div key={index}>
                          <input
                            type="radio"
                            id={`option${index}`}
                            name="options"
                            value={option.transcript_name} // Assuming 'value' is the property of the object representing the option
                            checked={selectedOption == option.transcript_name}
                            onChange={() =>
                              handleOptionChange(option.transcript_name, index)
                            }
                          />
                          <label htmlFor={`option${index}`} className="ms-3">
                            {option.transcript_name}
                          </label>{" "}
                          {/* Assuming 'label' is the property of the object representing the option */}
                        </div>
                      ))}
                </div>
              </div>
              <div className="col-md-8">
                <button
                  onClick={downloadAsWord}
                  className="btn btn-success mb-4 mt-4"
                >
                  Download as Word
                </button>
                {/* Map over the object of arrays */}

                <div
                  style={{ border: "5px solid #3cdfff", borderTopWidth: "0px" }}
                  id="content"
                >
                  <div
                    className="fw-bold py-1"
                    style={{ background: "#3cdfff" }}
                  >
                    {selectedOption} Summary
                  </div>

                  <div className="ms-2 mt-2">
                    {Object.keys(sortedData) &&
                      Object.keys(sortedData).map((key, index) => (
                        <div key={key}>
                          <ul>
                            {sortedData[key].map((item) => (
                              <li key={index}>
                                {/* <p>{item.startTime}</p> */}
                                <span>{item.text}</span>{" "}
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </SideBar>
        </PageLayout>
      </div>
    </>
  );
};

export default NewPage;
