import React from "react";
import {useMsal} from "@azure/msal-react";
import {loginRequest} from "../authConfig";
import {useNavigate} from "react-router-dom";

export const SignInButton = () => {
    const {instance} = useMsal();
    const navigate = useNavigate();
    const handleLogin = () => {
        instance.loginPopup(loginRequest).then((res)=> {
            localStorage.setItem("rt-access-token",res.accessToken);
            navigate('/summary')
        }).catch(e => {
            console.log(e);
        });
    }
    return (
        <button variant="secondary"  className="overlap-button btn btn-danger" onClick={handleLogin}>Login With Microsoft</button>
    )
}
