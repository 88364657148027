import React, { useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { useState } from "react";
import { motion } from "framer-motion";
import "./SidebarMenu.css";
import { useNavigate } from "react-router-dom";

const SideBar = ({ children }) => {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState(false);
  const token = process.env.REACT_APP_ACCESS_TOKEN;
  console.log("Token", token)
  // const token =
  //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSIsImtpZCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYjkxN2VkZjEtYjQ4Mi00MTQ1LWE2YTUtYzAyODc3N2MzMTY4LyIsImlhdCI6MTcxMTA5MTA3MCwibmJmIjoxNzExMDkxMDcwLCJleHAiOjE3MTEwOTUwMTgsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVlFBcS84V0FBQUFVMU04S3FhUmFLYWZ0U040NXVrSXp0VnREVW1UWkcveUwvNFk3ZDhFVkpJeStjT0ZiQ1hOWjV2NE9TVGlpSVQrb2lJeE55WGFpUVlQbkJsNkhISHFveHNkeDRISTk2NVRIR1ZuY29rNXVvWT0iLCJhbXIiOlsicHdkIiwibWZhIl0sImFwcGlkIjoiODcxYzAxMGYtNWU2MS00ZmIxLTgzYWMtOTg2MTBhN2U5MTEwIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJBc2hpIiwiZ2l2ZW5fbmFtZSI6IkFiaGlraHlhIiwiaXBhZGRyIjoiMTAzLjE3My45My41MSIsIm5hbWUiOiJBYmhpa2h5YSAgQXNoaSIsIm9pZCI6IjBjMWZlYTM5LTljMDktNDQzMy04NTkzLTllNTc3YWU0YmUyZiIsInB1aWQiOiIxMDAzMjAwMzVBOUNCNzE0IiwicmgiOiIwLkFTZ0E4ZTBYdVlLMFJVR21wY0FvZDN3eGFBa0FBQUFBQUFBQXdBQUFBQUFBQUFBb0FLQS4iLCJzY3AiOiJ1c2VyX2ltcGVyc29uYXRpb24iLCJzaWduaW5fc3RhdGUiOlsia21zaSJdLCJzdWIiOiJIX0ZDTmhxVTdRWXAzc29NVUZRODEzcTBKckJHemxWYVJqZnREZmNQaTl3IiwidGlkIjoiYjkxN2VkZjEtYjQ4Mi00MTQ1LWE2YTUtYzAyODc3N2MzMTY4IiwidW5pcXVlX25hbWUiOiJBYmhpa2h5YUBjbG91ZGNvdXJ0aW5jLmNvbSIsInVwbiI6IkFiaGlraHlhQGNsb3VkY291cnRpbmMuY29tIiwidXRpIjoibi1oNy1LaDFtRTZ5MWJnSlVQb1ZBQSIsInZlciI6IjEuMCIsIndpZHMiOlsiZjJlZjk5MmMtM2FmYi00NmI5LWI3Y2YtYTEyNmVlNzRjNDUxIiwiY2YxYzM4ZTUtMzYyMS00MDA0LWE3Y2ItODc5NjI0ZGNlZDdjIiwiYjc5ZmJmNGQtM2VmOS00Njg5LTgxNDMtNzZiMTk0ZTg1NTA5Il19.mdifCu1dNvPPxTWOfblyPl3uKOBirEKMnlXljkDYTCEtpJyzsD1h2VZacnETUYyc7djaWiQfyVMsXB3GsK00gpPy3i904UqpJDoDPvM7qyuI_yBRGG0IzxYAaSUsyCT3aH4TO89ZmsWcEnR55W3wDNXAJ1bkVDHIepgJiCNoH-JXYIAqv9wiGvqsgv7sDtSTt4uRJezZyz7rAthNG3hhxIN5TfAYQMG378uAi2Y63EdB2P8A0G_X3SZ77mMRghLIpuyfYEwRrGDMcPBXk7-WjNGY6p00rp35IWF-yaObCE38WLPBwW1sOODHBJusD5xAZCihSa77c-j5Q2ZeJluC5A";
  const toggle = () => setIsOpen(!isOpen);
  // const [routes, setRoutes] = useState([]);
  // const [newRoute, setNewRoute] = useState([]);
  const [reportList, setReportList] = useState();
  // const [selectedReport, setSelectedReport] = useState(null);
  // const [sideBarData, setSidebarData] = useState([]);
  const [items, setItems] = useState([]);
  const [collapsedReports, setCollapsedReports] = useState({});

  // Function to toggle collapse state of report
  const toggleCollapse = (reportId) => {
    setCollapsedReports((prevState) => ({
      ...prevState,
      [reportId]: !prevState[reportId],
    }));
  };

  const handleClickOnPagename = (pageName, reportId) => {
    console.log("PageName", pageName);
    console.log("ReportId", reportId )
    navigate.push(`/users/${pageName}/${reportId}`);
  }
  const fetchPages = async (reportId) => {
    try {
      const response = await fetch(
        `https://api.powerbi.com/v1.0/myorg/reports/${reportId}/pages`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch pages data");
      }

      const jsonData = await response.json();
      return jsonData.value.map((page) => ({
        id: page.name,
        pageName: page.displayName, // assuming page name is stored in displayName property
      }));
    } catch (error) {
      console.error("Error fetching pages data:", error);
      return [];
    }
  };

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await fetch(
          "https://api.powerbi.com/v1.0/myorg/apps/726438cb-17ae-4c46-8c8c-ca57b4c1e84f/reports",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch reports data");
        }

        const jsonData = await response.json();
        console.log("My Reports", jsonData.value)
        const reportData = await Promise.all(
          jsonData.value.map(async (report) => {
            const pages = await fetchPages(report.id);
            console.log("My Pagesssss", pages)
            return {
              id: report.id,
              reportName: report.name,
              pages: pages,
            };
          })
        );
        const reversedData = [...reportData].reverse(); // Reversing the data array

        setReportList(reversedData);
      } catch (error) {
        console.error("Error fetching reports data:", error);
      }
    };

    fetchReports();
  }, []);

  const fetchToken = () => {
    // const response = fetch("https://app.cloudcourt.ai/ms/token/")
  }

  useEffect(() => {
   fetchToken()
  }, []);

  console.log(reportList, " listtt");
  return (
    <>
      <div className="main-container">
        {console.log("itemsss", items)}
        <motion.div
          animate={{
            width: isOpen ? "260px" : "105px",

            transition: {
              duration: 0.5,
              type: "spring",
              damping: 10,
            },
          }}
          className={`sidebar `}
        >
          <div className="top_section">
            <div className="bars">
              <FaBars onClick={toggle} />
            </div>
          </div>
          <section className="routes">
            {isOpen &&
              reportList?.map((report) => (
                <>
                <div key={report.id}>
                  <div
                    onClick={() => toggleCollapse(report.id)}
                    className={`reportName ${
                      collapsedReports[report.id] ? "collapsed" : ""
                    }`}
                  >
                    <strong className="text-nowrap">{report.reportName=="Gibson_Jan6"? "GibsonDemo (GovInfo data)":(report.reportName == "Gibson_UI_v1.3.7"? "GibsonDemo (IPR)": report.reportName)}</strong>
                  </div>
                  {collapsedReports[report.id] && (
                    <ul style={{ listStyleType: "none", marginLeft: "1em", wrap: "no" }}>
                      {report?.pages?.map((page) => (
                        <>
                        <li className="text-nowrap" key={page.id} onClick={()=> handleClickOnPagename(page.id, report.id)}>{
                          (page.pageName=="Drill-down detail"||page.pageName=="QC Team"||page.pageName=="Release Notes"||page.pageName=="Page 1"||page.pageName=="WitnessList"?"":page.pageName)
                          }</li>
                         
                        
                        </>
                      ))}
                       <li onClick={()=>navigate.push("/summary")}>
                            {report.reportName=="Gibson_UI_v1.3.7"?"Summary": ""}
                          </li>
                      
                    </ul>

                  )}
                 
                </div>
                
                </>
                
              ))}
          </section>
        </motion.div>
        <main>{children}</main>
      </div>
    </>
  );
};

export default SideBar;
