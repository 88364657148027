import React from "react";
import {useMsal} from "@azure/msal-react";

export const SignOutButton = () => {
    const {instance} = useMsal();
    const handleLogout = () => {
        localStorage.removeItem("rt-access-token");
        instance.logoutPopup({
            postLogoutRedirectUri: "/login",
            mainWindowRedirectUri: "/login"
        });
    }    
    return (
        <button variant="secondary" className="overlap-button btn btn-danger" drop="start" title="Sign Out" onClick={handleLogout}>Sign Out</button>
    )
}