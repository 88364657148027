import React, {useState, useEffect} from "react";
import { PageLayout } from "../components/PageLayout";
import SideBar from "../components/Sidebar/SideBar";
import Testimony1 from "../data/testimony/FormattedQA/Testimony1.json";
import Testimony2 from "../data/testimony/FormattedQA/Testimony2.json";
import Testimony3 from "../data/testimony/FormattedQA/Testimony3.json";
import Testimony4 from "../data/testimony/FormattedQA/Testimony4.json";
import Testimony5 from "../data/testimony/FormattedQA/Testimony5.json";
import Testimony6 from "../data/testimony/FormattedQA/Testimony6.json";
import GibsonLogo from "../img/gibson_logo.png";
import MultiSelect from "../components/Select/MultiSelect";
import { DataGrid } from "@mui/x-data-grid";

function Testimony() {

      const [testimonyCount, setTestimonyCount] = useState(0);
      const [rows, setRows] = useState(Testimony1);
      const [searchQuery, setSearchQuery] = useState('');
      const [filteredRows, setFilteredRows] = useState(Testimony1);
      const [selectedTranscripts, setSelectedTranscripts] = useState(null);

      const handleTranscriptChange = (transcriptName) => {
        setSelectedTranscripts(transcriptName);

      }
      const columns = [
        { field: "index", headerName: "File Name", width: 150 },
        { field: "question", headerName: "Q", width: 150 },
        { field: "answer", headerName: "A", width: 150 },
        // { field: "country", headerName: "Transcript", width: 250 },
        { field: "case_name", headerName: "Case", width: 250 },
        
      ];
     

      const testimony = [
        'IPR2017-01812 1126 - Joey Chen Deposition',
        'IPR2019-01313 1022 - Lipson Deposition',
        'IPR2019-01313 1023-Meadows Deposition',
        'IPR2019-01318 2012 - Pless Deposition',
        'IPR2019-01318 2013 - Kroll Transcript',
        'IPR2019-01318 1011 - Ronald Berger Deposition'
      ];

      useEffect(()=> {
        const rowsWithAdditionalValues = Testimony1.map(row => ({
            ...row,
            // Add additional values here, for example:
            country: 'USA', // Example additional value
          }));
          setRows(rowsWithAdditionalValues);
      },[])

      useEffect(() => {
      }, [selectedTranscripts]);
  return (
    <PageLayout>
      <SideBar>
        <img
          src={GibsonLogo}
          alt="logo"
          width="380"
          class="rounded mx-auto d-block"
        />
        <div className="row">
          <div className="col-md-3">
          <p className="fw-bold text-nowrap">Search by Witness Name</p>
          <div>{console.log("selectedTranscripts" ,selectedTranscripts)}</div>
                  <input
                    type="text"
                    class="form-control mb-4"
                    id="exampleFormControlInput1"
                    placeholder="Search"
                    // onChange={(e) => handleSearchByWitness(e)}
                  />
          
          </div>
          <div className="col-md-4">
              <p className="fw-bold text-nowrap">Transcript (Select on or more)</p>
              {/* <select
                    className="form-control text-nowrap"
                    // value={alignment}
                    // onChange={handleAlignmentChange}
                  >
                    {testimony.map((item, index) =>
                        <option key={index} value={testimony}>
                          {item}
                        </option>
                    )}
                  </select> */}
                  <MultiSelect options={testimony} transcripts={handleTranscriptChange}/>
          
          </div>
          <div className="col-md-3">
              <p className="fw-bold text-nowrap">Search All Testimony</p>
              <input
                    type="text"
                    class="form-control mb-4"
                    id="exampleFormControlInput1"
                    placeholder="Search"
                    // onChange={(e) => handleSearchByWitness(e)}
                  />
          
          </div>
          <div className="col-md-2">
              <p className="fw-bold text-nowrap">Testimony Count</p>
              <p>{testimonyCount}</p>
          
          </div>
         
        </div>
        <div className="ms-2 mt-2" id="content">
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={5}
                      getRowId={(row) => row.index}
                      headerClassName="transcriptStyle"
                    />
                  </div>
      </SideBar>
    </PageLayout>
  );
}

export default Testimony;
