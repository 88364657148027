import React, { useEffect, useRef, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "./Dashboard.css";
import * as pbi from "powerbi-client";
import { useParams } from "react-router";
import SideBar from "../components/Sidebar/SideBar";
import { PageLayout } from "../components/PageLayout";

function Users() {
  const myElementRef = useRef(null);
  const { pageId, reportId } = useParams();
  const [pageNames, setPageNames] = useState([]);
  const [routes, setRoutes] = useState([]);
  
  const token = process.env.REACT_APP_ACCESS_TOKEN;
  console.log("pageId", pageId);
  console.log("reportId", reportId)

  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await fetch('https://api.powerbi.com/v1.0/myorg/reports/dee65843-3876-4415-a3b4-f1da99151366/pages', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }

        const jsonData = await response.json();
        console.log('Power BI Pages:', jsonData.value);
        setRoutes(jsonData.value);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPages();
  }, []);

  useEffect(()=> {
      for(let i = 0 ; i< routes.length ; i++ ){

      }
  },[])
  return (
    <PageLayout>
      <SideBar>
      {/* {console.log("pagename", pageName)} */}
      {/* <h1>Current Page: {pageName}</h1> */}
      <div ref={myElementRef} id="myElement">
      {pageNames.map((name, index) => (
          console.log("Page Names", name)
        ))}        <div className="child">
          <div id="reportContainer">
            <PowerBIEmbed
            
              embedConfig={{
                pageName: pageId,
                type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create

                id: reportId,
                embedUrl: `https://app.powerbi.com/reportEmbed?reportId=7402e3f1-7a87-41b5-a323-67f4990d486d&groupId=9f4dfd21-abab-42e1-ab2c-d57e10092f27&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRy1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d`,
                // accessToken:'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSIsImtpZCI6IlhSdmtvOFA3QTNVYVdTblU3Yk05blQwTWpoQSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYjkxN2VkZjEtYjQ4Mi00MTQ1LWE2YTUtYzAyODc3N2MzMTY4LyIsImlhdCI6MTcxMDQxMDU4NSwibmJmIjoxNzEwNDEwNTg1LCJleHAiOjE3MTA0MTUwNzUsImFjY3QiOjAsImFjciI6IjEiLCJhaW8iOiJBVFFBeS84V0FBQUFQeTFCZ0Z0eW1rMVRqbE9YQXlpeHMwZW9sUStBUnBEMTM0MStkeTdGUVVpSjZxTkptOVE1Q2ZNc2F0cVYvMnMwIiwiYW1yIjpbInB3ZCJdLCJhcHBpZCI6Ijg3MWMwMTBmLTVlNjEtNGZiMS04M2FjLTk4NjEwYTdlOTExMCIsImFwcGlkYWNyIjoiMCIsImZhbWlseV9uYW1lIjoiQXNoaSIsImdpdmVuX25hbWUiOiJBYmhpa2h5YSIsImlwYWRkciI6IjEwMy4xNzMuOTMuNTEiLCJuYW1lIjoiQWJoaWtoeWEgIEFzaGkiLCJvaWQiOiIwYzFmZWEzOS05YzA5LTQ0MzMtODU5My05ZTU3N2FlNGJlMmYiLCJwdWlkIjoiMTAwMzIwMDM1QTlDQjcxNCIsInJoIjoiMC5BU2dBOGUwWHVZSzBSVUdtcGNBb2Qzd3hhQWtBQUFBQUFBQUF3QUFBQUFBQUFBQW9BS0EuIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic2lnbmluX3N0YXRlIjpbImttc2kiXSwic3ViIjoiSF9GQ05ocVU3UVlwM3NvTVVGUTgxM3EwSnJCR3psVmFSamZ0RGZjUGk5dyIsInRpZCI6ImI5MTdlZGYxLWI0ODItNDE0NS1hNmE1LWMwMjg3NzdjMzE2OCIsInVuaXF1ZV9uYW1lIjoiQWJoaWtoeWFAY2xvdWRjb3VydGluYy5jb20iLCJ1cG4iOiJBYmhpa2h5YUBjbG91ZGNvdXJ0aW5jLmNvbSIsInV0aSI6Inp4aGxKLXpQVlV5ZGQ0bkgzTXM4QUEiLCJ2ZXIiOiIxLjAiLCJ3aWRzIjpbImI3OWZiZjRkLTNlZjktNDY4OS04MTQzLTc2YjE5NGU4NTUwOSJdfQ.QWafAa3gzSZfHZrHbfTFm5WA5FSCtOsMtqGL8zHfrlSRiMqVfb57lFCdWg4qCkcx1_CFXDEX2RFP85xfnZwvF51kj1NM9qFytoePgJQPdXrV5fbKZWA_FmN3JX7pbFwomjZMMwPugxGgH2rrTo5EEJJLwVGbILoGzdJD2LYT2-LAF_BZ_y-3JIcp4GMwTHoL7Sel6LhK6Hiub3IdI48Q9aJqwje7qDCZgziLOJgnAVXPfmbR5PW9OI61AN7fFP8U39C_5U8k8mFaUChbYm6SWuLn3rQHXRznoo2e10jG9hQrFuBrq9LGc80gap6dJdR3iOAO4PaFVx0ThL4rpDNRmg',
                accessToken: token,
                settings: {
                  panes:{
                    pageNavigation: {
                        visible: true,  
                    }
                }    
                },

              }}

              cssClassName={"custom-powerbi-embed"}
              getEmbeddedComponent={(embeddedReport) => {
                window.report = embeddedReport;
              }}
            />
          </div>
        </div>
      </div>
      </SideBar>
    </PageLayout>
  );
}

export default Users;
