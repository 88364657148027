import React from "react";
import "./App.css";
import {Routes,BrowserRouter as Router,Route,Navigate} from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import Users from "./pages/Users";
import UserAppsList from "./pages/UserAppsList";
import Messages from "./pages/Messages";
import Transcripts from "./pages/Transcripts";
import Summary from "./pages/Summary";
import Login  from "./pages/user/Login";
import Testimony from "./pages/Testimony";

const App = () => {
    const checkAuthenticated = () => {
        const accessToken = localStorage.getItem("rt-access-token") || null;
        if(accessToken){
            return true;
        }
        return false;
    }
    const PrivateRoute = ({children}) => {
        let isAuthenticated = checkAuthenticated();
        return isAuthenticated ? children : <Navigate to="/login" />;
    };
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<Summary/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/users/:pageId/:reportId" element={<Users />} />
                    {/* <Route path="/userApps" element={<PrivateRoute><UserAppsList /></PrivateRoute>} /> */}
                    <Route path="/userApps" element={<UserAppsList />}/>
                    <Route path="/messages" element={<PrivateRoute><Messages /></PrivateRoute>} />
                    <Route path="/transcripts" element={<Transcripts/>}/>
                    <Route path="/testimony" element={<Testimony/>}/>
                    <Route path="/summary" element={<Summary/>}/>
                </Routes>
            </Router>
        </div>
    );
}
export default App;
