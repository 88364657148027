import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";

const renderCellWithLink = (params) => {
    // Assuming the 'link' field in the row data contains the URL of the link
    // const linkUrl = params.row.link;
    return <Link to="/users">{params.value}</Link>;
  };
const columns = [
  { field: "name", headerName: "Name", width: 150, renderCell: renderCellWithLink},
  { field: "publishedBy", headerName: "Publisher", width: 150 },
  { field: "lastUpdate", headerName: "Published", width: 250 },
];



const UserAppsList= () => {
  const [appList, setAppList] = useState([]);
  useEffect(() => {
    const fetchApps = async () => {
      try {
        const response = await fetch(
          "https://api.powerbi.com/v1.0/myorg/apps",
          {
            headers: {
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6InEtMjNmYWxldlpoaEQzaG05Q1Fia1A1TVF5VSIsImtpZCI6InEtMjNmYWxldlpoaEQzaG05Q1Fia1A1TVF5VSJ9.eyJhdWQiOiJodHRwczovL2FuYWx5c2lzLndpbmRvd3MubmV0L3Bvd2VyYmkvYXBpIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvYjkxN2VkZjEtYjQ4Mi00MTQ1LWE2YTUtYzAyODc3N2MzMTY4LyIsImlhdCI6MTcxMzc4MDI2MywibmJmIjoxNzEzNzgwMjYzLCJleHAiOjE3MTM3ODQxNjMsImFpbyI6IkUyTmdZRml0b3RHcU5Gazc2ME9Va3BGcTgrdmxBQT09IiwiYXBwaWQiOiIwNDE0ODk0MC0wNTBiLTQyYmYtOTMzNC1kMDlhMjk2MTc0ZTMiLCJhcHBpZGFjciI6IjEiLCJpZHAiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC9iOTE3ZWRmMS1iNDgyLTQxNDUtYTZhNS1jMDI4Nzc3YzMxNjgvIiwiaWR0eXAiOiJhcHAiLCJvaWQiOiIyMzgyNjE3MC02ZTE2LTQzOTQtYmQyZS00Y2EyMzczODUzMzciLCJyaCI6IjAuQVNnQThlMFh1WUswUlVHbXBjQW9kM3d4YUFrQUFBQUFBQUFBd0FBQUFBQUFBQUFvQUFBLiIsInN1YiI6IjIzODI2MTcwLTZlMTYtNDM5NC1iZDJlLTRjYTIzNzM4NTMzNyIsInRpZCI6ImI5MTdlZGYxLWI0ODItNDE0NS1hNmE1LWMwMjg3NzdjMzE2OCIsInV0aSI6ImRnRkphZ0N4SVVLdTlMR3d5QWxMQUEiLCJ2ZXIiOiIxLjAifQ.GMlks9Nerl8Rqn-FrJvQKpKmbWrGfyo1xFwvkGAdrZEDf4REdY90d4Wgi034-6SgnpSCOXvIlW9enOLYMLtv_xARw-zV0-3wGFQW2zZKwAuAsOb7Cs76JhJE3bjnZJnP_JouIxrbeaf9s7LPkPXSIK4zNleSIcz3KHTAVp_V4ex8ZN_tdsejj_GSKnljcwvK5B3jVCy5ez6c1mROCwJPXIjspY0QRxoKZ05ebS_vQSi25Fd0L8rZRVgXwmPIGZVvhLg_QOC4iO9uTc-TQh5KDWrlrfez274FC9IYZNeafNOO6gzXydPBKH_Dift1akl0pNBjx-H5maCcISIJOpuYOQ",
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const jsonData = await response.json();
        console.log("Power BI Apps:", jsonData.value);

        setAppList(jsonData.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchApps();
  }, []);

  //   if (loading) return <div>Loading...</div>;
  //   if (error) return <div>Error: {error.message}</div>;
  //   if (!data.length) return <div>No data available</div>;
  return (
    <div className="container">
      <h4>Apps</h4>
      <p>
        Apps are collections of dashboards and reports in one easy-to-find
        place.
      </p>
      <DataGrid
        rows={appList}
        columns={columns}
        options={{
          paging: false,
        }}
      />
    </div>
  );
};

export default UserAppsList;
