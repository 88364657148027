import React, { useState, useReducer, useEffect } from "react";
import htmlDocx from "html-docx-js/dist/html-docx";
import SideBar from "../components/Sidebar/SideBar";
import { PageLayout } from "../components/PageLayout";
import GibsonLogo from "../img/gibson_logo.png";
import WitnessMetaData from "../data/transcripts/File_metadata_master.json";
import Summary1 from "../data/summary/Summary1.json";
import Transcript2 from "../data/summary/Summary2.json";
import Transcript3 from "../data/summary/Summary3.json";
import Transcript4 from "../data/summary/Summary4.json";
import Transcript5 from "../data/summary/Summary5.json";
import { DataGrid } from "@mui/x-data-grid";
import Witness_taxonomy from "../data/transcripts/Witness_taxonomy.json";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

const initialState = {
  selectedOption: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "SELECT_OPTION":
      return { ...state, selectedOption: action.payload };
    default:
      return state;
  }
}

const columns = [
  {
    field: "witness_name",
    headerName: "Witness (File Metadata)",
    width: 150,
  },
  { field: "transcript_date", headerName: "Date", width: 150 },
  { field: "transcript_name", headerName: "Transcript", width: 250 },
  { field: "case_name", headerName: "Case", width: 250 },
];
const Transcript = () => {
  const [transcript, setTranscript] = useState(Summary1);
  const [witnessName, setWitnessName] = useState("");
  const [witnesses, setWitnesses] = useState([]);
  const [allTranscripts, setAllTranscript] = useState([]);

  const [selectedValue, setSelectedValue] = useState("");

  // Handle change in select dropdown
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    const witnesses = WitnessMetaData.filter(
      (witnessObj) => witnessName === witnessObj.witness_name
    );
    if (witnesses.length > 0) {
      setWitnesses(witnesses);
    } else {
      setWitnesses(WitnessMetaData);
    }
  }, [witnessName]);

  const downloadAsWord = () => {
    const divContent = document.getElementById("content").innerHTML;

    // Convert HTML content to Word document format
    const wordDocument = htmlDocx.asBlob(divContent);

    // Create a link element
    const a = document.createElement("a");
    // Create a URL to the blob
    const url = URL.createObjectURL(wordDocument);
    // Set the href attribute of the link to the URL of the blob
    a.href = url;
    // Set the filename for the downloaded file
    a.download = "summary.docx";
    // Append the link to the body
    document.body.appendChild(a);
    // Trigger the click event on the link
    a.click();
    // Remove the link from the body
    document.body.removeChild(a);
    // Revoke the URL to release the blob's resources
    URL.revokeObjectURL(url);
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const handleOptionChange = (option, index) => {
    dispatch({ type: "SELECT_OPTION", payload: option });
    if (option == "IPR2019-01313 1022 - Lipson Deposition") {
      setTranscript(Summary1);
    } else if (option == "IPR2017-01812 1126 - Joey Chen Deposition") {
      setTranscript(Transcript2);
    } else if (option == "IPR2019-01313 1023-Meadows Deposition") {
      setTranscript(Transcript3);
    } else if (option == "IPR2019-01318 2012 - Pless Deposition") {
      setTranscript(Transcript4);
    }
    if (option == "IPR2019-01318 2013 - Kroll Transcript") {
      setTranscript(Transcript5);
    }
  };

  const handleSearchByWitness = (e) => {
    setWitnessName(e.target.value);
  };
  return (
    <>
      {console.log("transcriptssss", witnesses)}
      <div>
        <br />
        <PageLayout>
          <SideBar>
            <img
              src={GibsonLogo}
              alt="logo"
              width="380"
              class="rounded mx-auto d-block"
            />

            <div className="row">
              <div className="col-md-4">
                <div className="container">
                  <p className="fw-bold">Search by Witness Name</p>
                  <input
                    type="text"
                    class="form-control mb-4"
                    id="exampleFormControlInput1"
                    placeholder="Search"
                    onChange={(e) => handleSearchByWitness(e)}
                  />
                  <p className="fw-bold" htmlFor="dataSelect">
                    Select Witness Alignment
                  </p>
                  <select
                    className="form-control"
                    id="dataSelect"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <option value="">Select an option</option>
                    {/* Iterate over the keys of the object */}
                    {Object.keys(Witness_taxonomy).map((key) =>
                      // Map over each array of objects
                      Witness_taxonomy["witness"].map((item) => (
                        // Render an option for each item
                        <option key={item.id} value={item.alignment}>
                          {item.alignment}
                        </option>
                      ))
                    )}
                  </select>

                  <p className="fw-bold pt-4" htmlFor="dataSelect">
                    Case
                  </p>
                  <select
                    className="form-control"
                    id="dataSelect"
                    value={selectedValue}
                    onChange={handleChange}
                  >
                    <option value="">Select an option</option>
                    {/* Iterate over the keys of the object */}
                    {WitnessMetaData.map((item, index) => (
                      <option key={index} value={item.case_name}>
                        {item.case_name}
                      </option>
                    ))}
                  </select>
                  <div className="row">
                    <div className="col-md-6">
                      <Card className="mt-5">
                        <Card.Header className="fw-bold">Witness Count</Card.Header>
                        <Card.Body style={{backgroundColor: "#3cdfff"}}>
                          {/* <Card.Title>Special title treatment</Card.Title> */}
                          <Card.Text>
                            With supporting text below as a natural lead-in to
                            additional content.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-6">
                      <Card className="mt-5">
                        <Card.Header className="fw-bold">Transcript Count</Card.Header>
                        <Card.Body style={{backgroundColor: "#3cdfff"}}>
                          {/* <Card.Title>Special title treatment</Card.Title> */}
                          <Card.Text>
                            With supporting text below as a natural lead-in to
                            additional content.
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <button
                  onClick={downloadAsWord}
                  className="btn btn-success mb-4 mt-4"
                >
                  Download as Word
                </button>
                {/* Map over the object of arrays */}

                <div
                  style={{ border: "5px solid #3cdfff", borderTopWidth: "0px" }}
                >
                  <div
                    className="fw-bold py-1"
                    style={{ background: "#3cdfff" }}
                  >
                    Deposition Summary
                  </div>

                  <div className="ms-2 mt-2" id="content">
                    <DataGrid
                      rows={witnesses.filter((item) => {
                        return witnesses.witness_name
                          ? // .toLowerCase() === ""
                            item
                          : item.witness_name
                              // .toLowerCase()
                              .includes(witnessName);
                      })}
                      columns={columns}
                      options={{
                        paging: false,
                      }}
                      getRowId={(row) => row.transcript_name}
                      headerClassName="transcriptStyle"
                    />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </SideBar>
        </PageLayout>
      </div>
    </>
  );
};

export default Transcript;
