import React, { useEffect, useRef, useState } from "react";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";
import "../../styles/index.css";
import * as pbi from "powerbi-client";
import { useParams } from "react-router";
import { useIsAuthenticated } from '@azure/msal-react';
import { SignInButton } from '../../components/SignInButton';
import { SignOutButton } from '../../components/SignOutButton';

function Users() {
  const reportRef = useRef(null);
  const { pageId, reportId } = useParams();
  const [pageNames, setPageNames] = useState([]);
  const [routes, setRoutes] = useState([]);
  const isAuthenticated = useIsAuthenticated();

  const token = process.env.REACT_APP_ACCESS_TOKEN;
  console.log("pageId", pageId);
  console.log("reportId", reportId);

  useEffect(() => {
    if (reportRef.current) {
      const report = reportRef.current.report;

      // Remove all filters from the report
      report.removeFilters()
        .catch(error => {
          console.error('Failed to remove filters:', error);
        });
    }
  }, [reportRef]);
  
  useEffect(() => {
    const fetchPages = async () => {
      try {
        const response = await fetch(
          "https://api.powerbi.com/v1.0/myorg/reports/dee65843-3876-4415-a3b4-f1da99151366/pages",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const jsonData = await response.json();
        console.log("Power BI Pages:", jsonData.value);
        setRoutes(jsonData.value);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchPages();
  }, []);

  useEffect(() => {
    for (let i = 0; i < routes.length; i++) {}
  }, []);
  return (
    <>
      {/* {console.log("pagename", pageName)} */}
      {/* <h1>Current Page: {pageName}</h1> */}
      <div className="child">
        <div className="container">
        <div id="reportContainer">
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual, qna, paginated report and create
              id: "7402e3f1-7a87-41b5-a323-67f4990d486d",
              embedUrl: `https://app.powerbi.com/reportEmbed?reportId=7402e3f1-7a87-41b5-a323-67f4990d486d&groupId=9f4dfd21-abab-42e1-ab2c-d57e10092f27&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLVVTLU5PUlRILUNFTlRSQUwtRy1QUklNQVJZLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0IiwiZW1iZWRGZWF0dXJlcyI6eyJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZSwiZGlzYWJsZUFuZ3VsYXJKU0Jvb3RzdHJhcFJlcG9ydEVtYmVkIjp0cnVlfX0%3d`,
              accessToken: token,
              settings: {
                panes: {
                  pageNavigation: {
                    visible: false,
                  },
                },
              },
            }}
            cssClassName={"custom-powerbi-embeded"}
            getEmbeddedComponent={(embeddedReport) => {
              window.report = embeddedReport;
            }}
            // ref={reportRef}

          />
          {isAuthenticated ? <SignOutButton /> : <SignInButton />}

          {/* <button type="button" className="overlap-button btn btn-danger">Login Button via Microsoft</button> */}
        </div>
        </div>
      </div>
    </>
  );
}

export default Users;
